import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41bfc8a3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = {
  href: "/ycf/kplist"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_van_image = _resolveComponent("van-image");
  const _component_van_row = _resolveComponent("van-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", _hoisted_2, [_createVNode(_component_el_button, null, {
    default: _withCtx(() => [_createTextVNode("返回")]),
    _: 1
  })]), _createElementVNode("h2", null, _toDisplayString($data.data.title), 1), _createVNode(_component_van_row, null, {
    default: _withCtx(() => [_createVNode(_component_van_image, {
      fit: "contain",
      width: "96%",
      style: {
        "float": "right",
        "margin-bottom": "10px"
      },
      src: $data.data.imageInput
    }, null, 8, ["src"])]),
    _: 1
  }), _createElementVNode("div", {
    innerHTML: $data.data.content,
    style: {
      "margin": "15px"
    }
  }, null, 8, _hoisted_3)]);
}