// import routes from '../router/routes'

export default {
  name: 'kplist',
  data() {
    return {
      showAll: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: '选项一'
      }, {
        text: '选项二'
      }, {
        text: '选项三'
      }],
      activeNames: ['1'],
      studentInfo: {
        idCard: '000000000000000000'
      },
      kplist: [],
      planTemp: {},
      data: {}
    };
  },
  methods: {},
  created() {
    console.log(1111);
    var data = history.state;
    this.data = data;
    console.log(data);
  },
  mounted() {}
};